/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'

const RemoteAdvisoryCasestudies = () => {
  const RemoteAdvisoryCasestudiesImages = useStaticQuery(graphql`
      query RemoteAdvisoryCasestudiesImages {
        liberty: file(absolutePath: {regex: "/assets/images/services/remoteadvisory/LibertyRemoteThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        spw: file(absolutePath: {regex: "/assets/images/services/remoteadvisory/SPWRemoteThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <div className="spb-10 Casestudies-s">
        <Container>
          <Row>
            <Col sm="12" lg="5" xl="4" className="titlef sp-5">
              <h2>We are currently helping companies like yours deliver remotely</h2>
            </Col>
           </Row>
           <Row>
              <Col sm="6">
                <div>
                  <div className="project-img">
                    <Image className="img-fluid" sizes={RemoteAdvisoryCasestudiesImages.liberty.childImageSharp.sizes} alt="Delivering a suit of self-service insurance tools remotely."/>
                  </div>
                  <small class="clientname liberty">Liberty direct insurance</small>
                  <h6 className="h3">Delivering a suit of self-service insurance tools remotely.</h6>
                  <p>We have created a fully remote solution delivery team made up of Urbian and internal Liberty teams in an ongoing transformation of the Liberty Direct Insurance digital self-service customer-facing and employee-facing products, all enabled by us launching their first ever insurance API.</p>
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <div className="project-img">
                    <Image className="img-fluid" sizes={RemoteAdvisoryCasestudiesImages.spw.childImageSharp.sizes} alt="Ongoing iterative improvements across work-streams remotely."/>
                  </div>
                  <small class="clientname spw">Sanlam Private Wealth</small>
                  <h6 className="h3">Ongoing iterative improvements across work-streams remotely.</h6>
                  <p>Ongoing remote-led digital transformation through the creation of a Design System used to deliver the new Sanlam Private Wealth brand website as well as a client service Handbook for portfolio managers.</p>
                </div>
              </Col>
            </Row>
        </Container>
      </div>
    )
  }


export default RemoteAdvisoryCasestudies
