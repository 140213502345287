/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import './styled.css'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

class RemoteAdvisoryHeadinfo extends React.Component {
  render() {
    return (
      <div className="spb-8 container testimonial border-top">
        <div className="row">
          <div className="col-12">
            <h2>We have partnered with Urbian long before lock-down and can honestly say that since moving our team fully remote we haven’t seen loss of quality and speed of delivery at all.</h2>
            <span>
              Anel Grobler, Head of Marketing and Communications at Sanlam Private Wealth
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default RemoteAdvisoryHeadinfo
