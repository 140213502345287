/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'

const RemoteAdvisoryTopimage = () => {
  const RemoteAdvisoryTopimages = useStaticQuery(graphql`
      query RemoteAdvisoryTopimages {
        topbanner: file(absolutePath: {regex: "/assets/images/services/remoteadvisory/RemoteAdvisoryBanner.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 2560) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container className="scontent-wrap introduction">
        <Row className="sp-5">
          <div className="pb-5 col-12">
            <Link className="reversearrow" to="/services">
              View all services
              <svg
                className="styled__Arrow-d87459-0 bQLhSh"
                width="14"
                height="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g stroke="#030303" fill="none" fillRule="evenodd">
                  <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                  <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                </g>
              </svg>
            </Link>
          </div>
          <Col lg="7">
            <h1>Remote advisory</h1>
            <p>After more than 12 years of working remotely ourselves, we share our tools and methodologies to help organisations make a smooth transition to highly effective remote teams at speed.</p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <figure>
              <Image className="img-fluid" sizes={RemoteAdvisoryTopimages.topbanner.childImageSharp.sizes} alt="One of our many Weekly Work Review ‘stand-ups’ via Zoom"/>
              <figcaption>
                One of our many Weekly Work Review ‘stand-ups’ via Zoom
              </figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="spb-10 border-bottom">
          <Col className="testimonial">
            <h2>Our partnership with Urbian has shown itself even more valuable since moving our team fully remote. We haven’t seen loss of momentum at all, actually I think we’ve seen in increase in the quality and speed of delivery.
            </h2>
            <span>
              Stephen Andrew, Head of IT at Liberty Insurance Direct
            </span>
          </Col>
        </Row>
      </Container>
    )
  }
export default RemoteAdvisoryTopimage
